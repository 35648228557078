import React, { useEffect, useRef } from 'react';
import './App.css';
import CA from './CA';
import CA2 from './CA2';

const videos = [
  '/Snaptik.app_7313907811447082246.mp4',
  '/Snaptik.app_7324309591850781958.mp4',
  '/Snaptik.app_7325454734737886469.mp4',
  '/Snaptik.app_7326284955984760110.mp4',
  '/Snaptik.app_7328786370246462725.mp4',
  
  '/Snaptik.app_7328865401633361157.mp4',
  '/Snaptik.app_7340281233307454725.mp4',
  '/Snaptik.app_7343249489987259654.mp4',
  
 
  

];

const videoSource = "/AdobeStock_403660374_Video_HD_Preview.mov";

function App() {
  const gridRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const trail = document.createElement('div');
      trail.className = 'cursor-trail';
      document.body.appendChild(trail);
      trail.style.left = `${e.pageX - 20}px`;
      trail.style.top = `${e.pageY - 20}px`;
      setTimeout(() => {
        trail.remove();
      }, 500); // Match the animation duration
    };

    document.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const grid = gridRef.current;
    const gridItems = Array.from(grid.children);

    gridItems.forEach((item, index) => {
      const row = Math.floor(index / 4) + 1;
      const col = (index % 4) + 1;
      item.style.gridRow = row;
      item.style.gridColumn = col;
      item.style.transform = `rotate(${Math.floor(Math.random() * 360)}deg)`;
    });
  }, []);

  const numRows = Math.ceil(videos.length / 4);
  const gridTemplateRows = `repeat(${numRows}, 1fr)`;

  return (
    <div className='home'>

      <video autoPlay="autoplay" loop="loop" muted="muted" className="video">
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
        <div className="App">

        <div className='divdiv2'>
            <h1 className='title'>Tole Tole</h1>
            
          </div>

          <div className="container">
            <div className="social-links">
              <a href="https://twitter.com/ToleTole_Coin" target="_blank" rel="noopener noreferrer">
                Twitter
              </a>
              <span className="separator">-</span>
              <a href="https://t.me/+hFVJsv2J6UMwY2Qx" target="_blank" rel="noopener noreferrer">
                Telegram
              </a>
              <span className="separator">-</span>
              <a href="https://dexscreener.com/solana/asx3urkfy13e6f4cz5mzrnoaoxxixyomnjorusiejgca" target="_blank" rel="noopener noreferrer">
                Dexscreener
              </a>
            </div>
            <div className="section">
              <CA />
            </div>
            <div className="section">
              <CA2 />
            </div>
          </div>
              <div
                ref={gridRef}
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gridTemplateRows: 'repeat(2, 1fr)',
                  columnGap: '10px', // Horizontal gap between columns
                  rowGap: '200px', // Vertical gap between rows, adjust as needed
                  width: '100%',
                  height: '75%',
                  
                }}
              >
                {videos.map((video, index) => (
                  <div key={index} style={{ width: '350px', height: '350px' }}>
                    <video style={{ width: '100%', height: '100%', objectFit: 'cover' }} autoPlay loop muted playsInline>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ))}
                <div id="cursor-trail"></div>
              </div>
            </div>
    </div>
    
  );
}

export default App;