import React, { useState } from 'react';
import './CA.css';

function CA() {
    const [paragraphText, setParagraphText] = useState('FDmiLmcJpA2wXA9wmvgYW1SCYUJSZAjKixFHZcmut2Hw');

    const handleButtonClick = () => {
        navigator.clipboard.writeText(paragraphText).then(() => {
            setParagraphText('copied');
            setTimeout(() => {
                setParagraphText('FDmiLmcJpA2wXA9wmvgYW1SCYUJSZAjKixFHZcmut2Hw');
            }, 500); // Change back after 1 second
        });
    };

    return (
        <main id="app">
            <button className="shimmer-button" onClick={handleButtonClick}>
                <span className="text">Contract Address</span>
                <p className='paragraph'>{paragraphText}</p>
                <span className="shimmer"></span>
            </button>
        </main>
    );
}

export default CA;
