
import React from 'react';
import './CA.css'; // Make sure the path to the CSS file is correct

function CA2() {
    return (
        <main id="app">
            <button className="shimmer-button">
                <span className="text">Tokenomics</span>
                <p className='paragraph'>Supply : 100,000,000</p>
                <p className='paragraph2'>Liquidity/Market : 80%</p>
                <p className='paragraph2'>Project Team : 20%</p>
                <span className="shimmer"></span>
            </button>
        </main>
    );
}

export default CA2;
